import {Notification} from '../../modules/notification.js';
import '../../components/dropdown.js';
import {PageSpinner} from '../../modules/spinner.js';
import {requestDialog} from '../../modules/dialog.js';
import {getHttpClient} from '../../modules/httpClient.js';

/**
 * Promote Or Demote Member
 * @param {HTMLDialogElement} dialogElement
 */
async function promoteOrDemoteMember(dialogElement) {
    const url = dialogElement.dataset.url;

    PageSpinner.start();
    try {
        const response = await getHttpClient().post(url);
        if (response) {
            window.location.reload();
        }
    } catch (error) {
        const errorText = error.message;
        const errorMessage = `Failed to change a member’s status: ${errorText}`;
        Notification.error(errorMessage);

        PageSpinner.stop();
    }
}

function initPromoteOrDemoteDialogs() {
    document.querySelectorAll('#promoteLocalLeaderConfirmation,#demoteLocalLeaderConfirmation').forEach((dialogElement) => {
        dialogElement.addEventListener('open', (event) => {
            const activationElement = event.detail;
            if (!activationElement) {
                event.preventDefault();
            } else {
                if (dialogElement.id === 'demoteLocalLeaderConfirmation') {
                    const localLeaderContainer = document.querySelector('.js-membersContainer--localLeaders');
                    if (localLeaderContainer.querySelectorAll('.jsMemberCardItem').length <= 1) {
                        requestDialog('demoteLastLeaderWarning');
                        event.preventDefault();
                        return;
                    }
                }

                dialogElement.dataset.url = activationElement.dataset.url;
                dialogElement.querySelectorAll('[data-template-variable="firstName"]').forEach((span) => {
                    span.textContent = activationElement.dataset.member;
                });
            }
        });

        dialogElement.addEventListener('close', () => {
            if (dialogElement.returnValue === 'CONFIRM_ACTION') {
                promoteOrDemoteMember(dialogElement);
            }
        });
    });
}

/**
 * Set Google Analytics fields on page load if a member is visiting
 * a local group page they is a member of.
 */
function setGoogleAnalyticsFields() {
    const isOwnLocalGroup = document.querySelector('[data-own-group="true"]');
    if (isOwnLocalGroup && window.ga) {
        window.ga('set', 'contentGroup1', 'Visits to own local group page');
    }
}

/**
 * Init page, register event handlers
 */
function initPage() {
    initPromoteOrDemoteDialogs();

    setGoogleAnalyticsFields();
}

// init page when the document is ready
document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', initPage) : initPage();
